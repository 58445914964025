import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Tips from the Experts`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` hosted a panel discussion on User Acquisition and Monetization Monday night at the `}<a parentName="p" {...{
        "href": "http://samsungnext.com/"
      }}>{`Samsung Accelerator`}</a>{`.`}</p>
    <p>{`The discussion offered fascinating insights into the strategies and learnings of leading bot experts.`}</p>
    <h3><strong parentName="h3">{`Panelists included:`}</strong></h3>
    <ul>
      <li parentName="ul">{`Arte Merritt (Moderator) CEO/Co-founder `}<a parentName="li" {...{
          "href": "http://www.dashbot.io/"
        }}>{`Dashbot`}</a></li>
      <li parentName="ul">{`Amit Kumar, CEO/Founder `}<a parentName="li" {...{
          "href": "http://www.notify.io/"
        }}>{`Notify.io`}</a></li>
      <li parentName="ul">{`Dan Gailey, CEO/Founder, `}<a parentName="li" {...{
          "href": "http://www.radbots.com/"
        }}>{`RadBots`}</a></li>
      <li parentName="ul">{`Dmitrii Dumik, CEO/Founder, `}<a parentName="li" {...{
          "href": "http://www.chatfuel.com/"
        }}>{`Chatfuel`}</a></li>
      <li parentName="ul">{`Peter Buchroithner, CEO/Co-founder `}<a parentName="li" {...{
          "href": "http://swell.wtf/"
        }}>{`Swell`}</a></li>
      <li parentName="ul">{`Stefan Behrans, Co-founder `}<a parentName="li" {...{
          "href": "http://www.gyant.com/"
        }}>{`Gyant`}</a></li>
      <li parentName="ul">{`Steijn Pelle, Growth Product Manager, `}<a parentName="li" {...{
          "href": "http://healthtap.com/"
        }}>{`HealthTap`}</a></li>
    </ul>
    <p>{`The panel offered incredibly valuable tips and techniques into what works for acquiring users and monetizing bots, as well as their thoughts on the bot space.`}</p>
    <p>{`You can watch the full discussion on `}<a parentName="p" {...{
        "href": "https://www.facebook.com/dashbot.io/videos/1258972054187210/"
      }}>{`Facebook Live`}</a>{`.`}</p>
    <p>{`The following are some of the key takeaways.`}</p>
    <h2>{`Have a Clear Value Proposition`}</h2>
    <p>{`It should be clear what your bot does. As Dan of RadBots mentioned, if the bot is solving a problem the user has, they will champion you.`}</p>
    <p>{`One of the particularly interesting learnings is that Swell created a longer on-boarding process to help users understand what the bot does and how to interact — along the lines of a tutorial but with a more natural feel. While this may have caused more drop off during on-boarding, they found it lowered the drop off later, effectively increasing retention rate as the users that went through the tutorial knew what the bot did and how to use it.`}</p>
    <p>{`On a related note, the bot should follow good behavior. While it’s not clear what rules Facebook follows for featuring a bot, Dmitrii of Chatfuel suspects good behavior might be one of the factors — if your bot has high engagement, high retention, and low block rates, it probably has a better chance of getting featured which would lead to more organic adoption.`}</p>
    <h2>{`Have a Clear, Recognizable Call to Action`}</h2>
    <p>{`One of the points Dmitrii made is to have a clear call to action for you bot, and to make use of traditional messaging users are familiar with — i.e. instead of “message us”, use “sign up.”`}</p>
    <p>{`If you’re building a Facebook bot, put the call to action in the cover photo.`}</p>
    <h2>{`Leverage Influencer Marketing`}</h2>
    <p>{`A common theme amongst the panel for how to best acquire users is to leverage influencer networks — people on Youtube and Facebook who have lots of followers.`}</p>
    <p>{`The general consensus is that Facebook Native Ads don’t work as well as influencer networks, regardless of whether the ad is directing a user to a landing page or directly to the bot itself. If you do try them, make sure to follow the recommendations for a clear call to action and use the structured message format. User acquisition can cost between 30 cents and \\$2.50.`}</p>
    <p>{`People tend to trust their friends and heros better.`}</p>
    <p>{`As Stefan of Gyant pointed out, there are two great benefits to working with Youtube influencers: (1) they have a lot of followers to get the message out to in a more cost effective way; and (2) they create videos which help explain what the bot is and how to use it, which is especially helpful for users who haven’t interacted with a bot before.`}</p>
    <p>{`The keys are to have your influencers create a video that explains how to interact with your bot and to come up with a plan for how often they will engage and promote your bot. Peter of Swelly mentioned they follow a 60 day plan with influencers — not that they’re doing something every day, but that they periodically interact and promote over a 60 day period.`}</p>
    <h2>{`Enable Sharing Capabilities`}</h2>
    <p>{`In addition to influencer marketing, one of the things that worked particularly well for Steijn at Healthtap was creating virality — enabling users to recommend the bot to their friends.`}</p>
    <p>{`Similarly, Swell sees 38% of the growth is organic through sharing capabilities. They follow up with re-engagement notifications and reminders.`}</p>
    <p>{`Dmitri recommended making use of sharing as well — and doing it at appropriate times in the bot.`}</p>
    <h2>{`Know Your Audience`}</h2>
    <p>{`Different platforms are popular in different countries. Some of these platforms skew to a particular user base — for instance it’s often said Kik skews to “teenagers in the US.”`}</p>
    <p>{`Knowing the platform, where it’s popular, and information about the user base can help you both in user acquisition and monetization.`}</p>
    <p>{`For example, Peter found Kik had a high level of virality as younger generations tend to share more frequently.`}</p>
    <p>{`If you are building for Telegram, Dmitri recommended submitting the bot to StoreBot.me and making sure it has good ratings. At the same time, make sure the bot incorporates group chat and encourage users to make use of the group chat — you can even have “super” group chats of 5,000 people!`}</p>
    <p>{`At the same time, Dmitrii pointed out that the largest user base for Telegram is in Iran. Yet, if you are trying to monetize those users, you wouldn’t be able to use PayPal, Stripe, and other services as those don’t work there — you’d have to have a relationship with a local provider which affects the ability to monetize.`}</p>
    <h2>{`Re-engage Your Users`}</h2>
    <p>{`Stefan found sending out re-engagement and “check in” messages in a non-obtrusive way works really well to get people back into the bot.`}</p>
    <p>{`Stefan ran a re-engagement campaign for Gyant using `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot’s`}</a>{` broadcast messaging feature and saw a large number of return users. They sent a very simple Christmas message and had 50,000 users return to the bot on one day, half of which tested themselves again for the Zvika virus. He found giving people an option to do something, and just prompting them, or reminding them about the bot, gets them to re-engage and interact with the bot.`}</p>
    <p>{`If you already have Facebook likes and fans, leverage those users to re-engage them and direct them to your bot.`}</p>
    <p>{`Amit of Notify.io works with a lot of enterprises who do this as they have larger existing fan bases. They also include “send to messenger” links at the bottom of articles to drive users to the bot. It works both ways as the messenger bot is used to drive re-engagement to the brands.`}</p>
    <h2>{`In Bot Ads Can be Effective`}</h2>
    <p>{`For monetization, in bot ads have higher response rates than traditional ads. One of RadBots customers is even seeing 25% conversion. Traditionally ads fight for users attention so they need to be done more carefully in messenger platforms, as they’re more intimate environments. Dan is finding ads done in a more targeted and integral way can be quite effective.`}</p>
    <p>{`Market Research works particularly well.`}</p>
    <p>{`Swelly has done an amazing job monetizing their bot by offering market research opportunities for brands. They’ve done so well, that Peter and his team created `}<a parentName="p" {...{
        "href": "http://waves.ai/"
      }}>{`Waves.ai`}</a>{` to enable other bot developers to monetize in a similar way — it’s a pretty exciting time for them!`}</p>
    <p>{`RadBots is also seeing decent interest from brands to advertise inside bots.`}</p>
    <h2>{`Bots are Experimenting with Monetization`}</h2>
    <p>{`Traditional, non advertising forms of monetization are possible.`}</p>
    <p>{`Stefan ran an experiment monetizing Gyant in Brazil which had encouraging results in terms of conversion rates around scheduling appointments and chatting with a doctor.`}</p>
    <p>{`Similarly, HealthTap is experimenting with how they can monetize their knowledge base and network of doctors, including working with other health related bots, like Stefan’s.`}</p>
    <p>{`Both Amit and Dmitrii are seeing brands offering traditional monetization opportunities. For example, users can purchase tickets for “The Crunchies” through the TechCrunch bot.`}</p>
    <p>{`Retailers in particular are attracted to bots, given user fatigue with traditional mobile apps. Not only are they offering monetization, they see higher engagement in their bots vs their apps.`}</p>
    <p>{`There still are challenges to overcome as not all users have their credit cards on file with the messenger platforms. Dmitrii mentioned WeChat overcame this by giving out 90 million dollars to users to encourage them to transact on the platform and connect their debit cards to their profiles — perhaps Facebook will do the same! 🙂`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we have bot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Slack, Google Home, Alexa, and Kik, and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      